<template>
  <b-overlay
    :show="data === null || disabledCache"
    rounded="sm"
  >
    <b-form
      v-if="data"
    >
      <b-row>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-group
            :label="$t('form.maintenance_mode.label')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Скинути системний кеш на сайті"
            label-for="cache"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabledCache"
              @click="clearCache"
            >
              Скинути
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.localization.language.title')"
            label-for="language"
          >
            <v-select
              v-model="data.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.value"
              :clearable="false"
              input-id="language"
            />
            <b-form-invalid-feedback v-if="errors && errors.language">
              {{ errors.language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.localization.admin_language.title')"
            label-for="admin_language"
          >
            <v-select
              v-model="data.admin_language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.value"
              :clearable="false"
              input-id="admin_language"
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_language">
              {{ errors.admin_language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.localization.currency.title')"
            label-for="currency"
          >
            <v-select
              v-model="data.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="currency"
            />
            <b-form-invalid-feedback v-if="errors && errors.currency">
              {{ errors.currency[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.localization.admin_currency.title')"
            label-for="admin_currency"
          >
            <v-select
              v-model="data.admin_currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="admin_currency"
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_currency">
              {{ errors.admin_currency[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_insta.label')"
            label-for="social_insta"
          >
            <b-form-input
              id="social_insta"
              v-model="data.social_insta"
              :state="errors && errors.social_insta ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_insta">
              {{ errors.social_insta[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_fb.label')"
            label-for="social_fb"
          >
            <b-form-input
              id="social_fb"
              v-model="data.social_fb"
              :state="errors && errors.social_fb ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_fb">
              {{ errors.social_fb[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_yt.label')"
            label-for="social_yt"
          >
            <b-form-input
              id="social_yt"
              v-model="data.social_yt"
              :state="errors && errors.social_yt ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_yt">
              {{ errors.social_yt[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_li.label')"
            label-for="social_li"
          >
            <b-form-input
              id="social_li"
              v-model="data.social_li"
              :state="errors && errors.social_li ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_li">
              {{ errors.social_li[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>

      <b-tabs>
        <b-tab
          v-for="lang in languages"
          :key="lang.value"
        >
          <template #title>
            <b-img
              :src="require('@/assets/images/flags/' + lang.value + '.svg')"
              height="16"
              width="16"
              class="mr-1"
            />
            <span class="d-none d-sm-inline">{{ lang.label }}</span>
          </template>
          <b-row>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_header.phone')"
                :label-for="'header.' + lang.value + '.phone'"
              >
                <b-form-input
                  :id="'header.' + lang.value + '.phone'"
                  v-model="data.header[lang.value].phone"
                  :state="errors && errors.header ? false : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_header.text')"
                :label-for="'header.' + lang.value + '.text'"
              >
                <b-form-input
                  :id="'header.' + lang.value + '.text'"
                  v-model="data.header[lang.value].text"
                  :state="errors && errors.header ? false : null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_footer.phone')"
                :label-for="'footer.' + lang.value + '.phone'"
              >
                <b-form-input
                  :id="'footer.' + lang.value + '.phone'"
                  v-model="data.footer[lang.value].phone"
                  :state="errors && errors.footer ? false : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_footer.text')"
                :label-for="'footer.' + lang.value + '.text'"
              >
                <b-form-input
                  :id="'footer.' + lang.value + '.text'"
                  v-model="data.footer[lang.value].text"
                  :state="errors && errors.footer ? false : null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_footer.phone')"
                :label-for="'footer.' + lang.value + '.phone2'"
              >
                <b-form-input
                  :id="'footer.' + lang.value + '.phone2'"
                  v-model="data.footer[lang.value].phone2"
                  :state="errors && errors.footer ? false : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_footer.text2')"
                :label-for="'footer.' + lang.value + '.text2'"
              >
                <b-form-input
                  :id="'footer.' + lang.value + '.text2'"
                  v-model="data.footer[lang.value].text2"
                  :state="errors && errors.footer ? false : null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_footer.brands')"
                label-for="footer_brands"
              >
                <v-select
                  v-model="data.footer[lang.value].brands"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="extensionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="footer_brands"
                  :searchable="true"
                  :state="errors && errors.footer ? false : null"
                  @search="searchExtension"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import StatusMixin from '@/mixins/StatusMixin'
import _ from 'lodash'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin, StatusMixin],
  data() {
    return {
      currencies: [],
      languages: [],
      extensionOptions: [],
      data: null,
      language: null,
      disabledCache: false,
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http('/api/currencies')
      .then(response => {
        this.currencies = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })

    await this.$http.get('/api/admin/extensions', {
      params: {
        per_page: 500,
      },
    })
      .then(response => {
        this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http('/api/admin/settings', { params: { group: 'general' } }).then(response => {
      this.data = this.transformData(response.data)

      /* eslint-disable camelcase */
      this.language = response.data.admin_language
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')
          await localStorage.setItem('localization', JSON.stringify({
            admin_language: this.data.admin_language,
            language: this.data.language.value,
          }))
          await this.changeLanguage()
          if (this.language !== this.data.admin_language) {
            window.location.reload()
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    clearCache() {
      this.confirm(() => {
        this.disabledCache = true
        setTimeout(() => {
          this.disabledCache = false
        }, 600000)
        this.$http.post('/api/admin/cache')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити скидання?',
        text: 'Скидання триває 5-10 хв у фоновому режимі (декілька разів нажимати не треба) і оновлює скрипти та переклади. У цей час не проводити дії з адмінкою через ризик втрати вашої роботи. Рекомендовано скидати кеш не частіше 1 разу на добу. Бажано скидати у період найменшої активності користувачів.',
      })
    },
    changeLanguage() {
      this.$i18n.locale = this.data.admin_language
      this.$i18n.fallbackLocale = this.data.language
    },
    async searchExtension(query) {
      await this.$http.get('/api/admin/extensions', {
        params: {
          query,
          per_page: 500,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    transformData(item) {
      const data = {
        maintenance_mode: item.maintenance_mode,
        social_fb: item.social_fb,
        social_insta: item.social_insta,
        social_li: item.social_li,
        social_yt: item.social_yt,
        language: item.language,
        admin_language: item.admin_language,
        currency: item.currency,
        admin_currency: item.admin_currency,
        header: item.header,
        footer: item.footer,
      }

      _.each(this.languages, language => {
        if (!data.header[language.value]) {
          data.header[language.value] = {
            phone: '(044) 503-34-59',
            text: '<p>9:00 до 18:00 <br>(Пн - ПТ)</p><span>Безкоштовно по Україні</span>',
          }
        }
        if (!data.header[language.value]) {
          data.footer[language.value] = {
            phone: '+38 044 503-34-59',
            text: 'Дзвонiть нам з 9:00 до 18:00 сб, нд - вихідний',
            phone2: '+38 044 503-34-59',
            text2: 'Дзвонiть нам з 9:00 до 18:00 сб, нд - вихідний',
            brands: 0,
          }
        }
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
