<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-tabs pills>
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">SMS</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.login.label')"
                label-for="sms_login"
              >
                <b-form-input
                  id="sms_login"
                  v-model="data.sms_login"
                  :state="errors && errors.sms_login ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.sms_login">
                  {{ errors.sms_login[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.password.label')"
                label-for="sms_password"
              >
                <b-form-input
                  id="sms_password"
                  v-model="data.sms_password"
                  :state="errors && errors.sms_password ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.sms_password">
                  {{ errors.sms_password[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.from.label')"
                label-for="sms_from"
              >
                <b-form-input
                  id="sms_from"
                  v-model="data.sms_from"
                  :state="errors && errors.sms_from ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.sms_from">
                  {{ errors.sms_from[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">NP</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              :md="data.np_api_key ? '8' : '12'"
            >
              <b-form-group
                label="API key"
                label-for="np_api_key"
              >
                <b-form-input
                  id="np_api_key"
                  v-model="data.np_api_key"
                  :state="errors && errors.np_api_key ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.np_api_key">
                  {{ errors.np_api_key[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              v-if="data.np_api_key"
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="mt-2"
                block
                :disabled="disabledNp"
                @click="runNP"
              >
                Update cities
              </b-button>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">WFP</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.login.label')"
                label-for="wayforpay_account"
              >
                <b-form-input
                  id="wayforpay_account"
                  v-model="data.wayforpay_account"
                  :state="errors && errors.wayforpay_account ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.wayforpay_account">
                  {{ errors.wayforpay_account[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.password.label')"
                label-for="wayforpay_secret"
              >
                <b-form-input
                  id="wayforpay_secret"
                  v-model="data.wayforpay_secret"
                  :state="errors && errors.wayforpay_secret ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.wayforpay_secret">
                  {{ errors.wayforpay_secret[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      data: null,
      disabledNp: true,
    }
  },
  async beforeCreate() {
    await this.$http('/api/admin/settings', { params: { group: 'integrations' } }).then(response => {
      this.data = response.data
    })
    await this.$http('/api/nova_poshta').then(response => {
      this.disabledNp = !response.data.can_sync
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/integrations', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    runNP() {
      this.confirm(() => {
        this.$http.post('/api/nova_poshta')
          .then(() => {
            this.disabledNp = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.disabledNp = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        text: this.$t('notifications.confirm.run_sync.text'),
      })
    },
  },
}
</script>
